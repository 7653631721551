import toast from 'react-hot-toast';
import Jsona from 'jsona';
import { t } from 'i18next';

import {
  DELETE_FILTERS,
  GET_CURRENT_USER,
  SET_WORK_ITEM,
  RESET_WORK_ITEM,
  RESET_BATCH,
  HS_ADD_ROW,
  HS_EDIT_FIELD,
  HS_REMOVE_ROW,
  HS_SELECT_ITEM,
  HS_SET_FILTERS,
  LOAD_APPLICATIONS,
  LOAD_FILTERS,
  LOAD_USERS,
  SET_BREADCRUMB,
  SETTINGS,
  SET_ALERTS_LIST,
  UPDATE_OUTPUT_FIELDS,
  UPDATE_DOCUMENTS,
  SET_DOCUMENT_TYPES,
  UPDATE_RULES,
  UPDATE_ALERTS,
  UPDATE_STATUS,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_ERROR,
  ON_DOCUMENT_DROP,
  ON_PAGE_DROP,
  SET_GROUPING_DATA,
  ADD_UNASSIGNED_DOCUMENT_SUCCESS,
  ADD_UNASSIGNED_DOCUMENT_LOADING,
  REMOVE_WORK_ITEM_LOADING,
  REMOVE_WORK_ITEM_SUCCESS,
  ADD_NEW_WORK_ITEM_LOADING,
  ADD_NEW_WORK_ITEM_SUCCESS,
  RESET_GROUPING,
  CHANGE_DOCUMENT_TYPE,
  UPDATE_SAVED_TAGS,
  UPDATE_EDITED_FIELD,
  REMOVE_DOCUMENT,
  SET_ADDITIONAL_FEES_OPTIONS,
  SET_APPROVAL_OVERRIDE_OPTIONS,
  GET_GROUPING_LEFT_SIDE_LOADING,
  GET_GROUPING_LEFT_SIDE_SUCCESS,
  GET_GROUPING_LEFT_SIDE_ERROR,
  GET_GROUPING_RIGHT_SIDE_LOADING,
  GET_GROUPING_RIGHT_SIDE_SUCCESS,
  GET_GROUPING_RIGHT_SIDE_ERROR,
  HS_SET_EDITABLE,
  HS_RESET_DOCUMENT,
  CLONE_WORK_ITEM_SUCCESS,
} from '@constants/action-types';
import api from '@services/axios';

export const saveSettings = (settings) => (dispatch) => {
  return dispatch({ type: SETTINGS, payload: settings });
};

export const setBreadcrumb = (breadcrumb) => (dispatch) => {
  return dispatch({ type: SET_BREADCRUMB, payload: breadcrumb });
};

export const loadApplications = (applications) => (dispatch) => {
  return dispatch({ type: LOAD_APPLICATIONS, payload: applications });
};

export const loadFilters = (appUuid, filters) => (dispatch) => {
  return dispatch({ appUuid, type: LOAD_FILTERS, payload: filters });
};

export const deleteFilters = () => (dispatch) => {
  return dispatch({ type: DELETE_FILTERS });
};

export const hsSetEditable = (editable) => (dispatch) => {
  return dispatch({ type: HS_SET_EDITABLE, payload: editable });
};

export const updateHsState = (selected) => (dispatch) => {
  return dispatch({ type: HS_SELECT_ITEM, payload: selected });
};

export const updateHSField = (field) => (dispatch) => {
  return dispatch({ type: HS_EDIT_FIELD, payload: field });
};

export const updateHSFilters = (filters) => (dispatch) => {
  return dispatch({ type: HS_SET_FILTERS, payload: filters });
};

export const hsAddRow = (row) => (dispatch) => {
  return dispatch({ type: HS_ADD_ROW, payload: row });
};

export const hsRemoveRow = (rowId) => (dispatch) => {
  return dispatch({ type: HS_REMOVE_ROW, payload: rowId });
};

export const resetHsState = () => (dispatch) => {
  return dispatch({ type: HS_RESET_DOCUMENT });
};

export const getUsers = () => (dispatch) => {
  return dispatch({ type: LOAD_USERS });
};

export const getCurrentUser = (user) => (dispatch) => {
  return dispatch({ type: GET_CURRENT_USER, payload: user });
};

export const setWorkItem = (workItem) => (dispatch) => {
  return dispatch({ type: SET_WORK_ITEM, payload: workItem });
};

export const resetWorkItem = () => (dispatch) => {
  return dispatch({ type: RESET_WORK_ITEM });
};

export const resetBatch = () => (dispatch) => {
  return dispatch({ type: RESET_BATCH });
};

export const setAlerts = (alerts) => (dispatch) => {
  return dispatch({ type: SET_ALERTS_LIST, payload: alerts });
};

export const updateOutputFields = (outputFields) => (dispatch) => {
  return dispatch({ type: UPDATE_OUTPUT_FIELDS, payload: outputFields });
};

export const updateDocuments = (documents) => (dispatch) => {
  return dispatch({ type: UPDATE_DOCUMENTS, payload: documents });
};

export const setDocumentTypes = (documentTypes) => (dispatch) => {
  return dispatch({ type: SET_DOCUMENT_TYPES, payload: documentTypes });
};

export const updateRules = (rules) => (dispatch) => {
  return dispatch({ type: UPDATE_RULES, payload: rules });
};

export const updateAlerts = (alerts) => (dispatch) => {
  return dispatch({ type: UPDATE_ALERTS, payload: alerts });
};

export const updateWorkItemStatus = (status) => (dispatch) => {
  return dispatch({ type: UPDATE_STATUS, payload: status });
};

export const getWorkItemByFilter = (filters) => async (dispatch) => {
  dispatch({ type: GET_GROUPING_RIGHT_SIDE_LOADING });
  api
    .jsonAPI()
    .get(`/work_items`, { params: filters })
    .then((res) => {
      dispatch({
        type: GET_GROUPING_RIGHT_SIDE_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({ type: GET_GROUPING_RIGHT_SIDE_ERROR });
    });
};

export const getWorkItemDocumentsById = (workItemId) => async (dispatch) => {
  dispatch({
    type: LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING,
    payload: workItemId,
  });
  api
    .jsonAPI()
    .get(`/work_items/${workItemId}`)
    .then((res) => {
      dispatch({
        type: LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({ type: LOAD_GROUPING_WORK_ITEM_DOCUMENTS_ERROR });
    });
};
export const onDocumentDropAction =
  (document) => async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;
    const { oldWorkItemId, newWorkItemId, documentId } = document;
    api
      .jsonAPI()
      .patch(
        `work_items/${oldWorkItemId}/documents/${documentId}/move_to?to_work_item_id=${newWorkItemId}`,
      )
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
      });

    return dispatch({ type: ON_DOCUMENT_DROP, payload: document });
  };

export const onPageDropAction = (dragInfo) => async (dispatch, getState) => {
  const {
    to,
    from,
    newDocumentId,
    oldDocumentId,
    pageIds,
    newWorkItemId,
    oldWorkItemId,
  } = dragInfo;
  // Prevent rearranging pages for the same document on the right side
  if (
    newDocumentId === oldDocumentId &&
    to === 'rightSide' &&
    from === 'rightSide'
  ) {
    return () => {};
  }
  const cachedReducer = getState().groupingReducer;
  api
    .jsonAPI()
    .patch(
      `/work_items/${oldWorkItemId}/documents/${oldDocumentId}/page_move_to`,
      {
        page_id: pageIds,
        to_document_id: newDocumentId,
        to_work_item_id: newWorkItemId,
        swapTo: dragInfo.replaceThisPageTo,
      },
    )
    .catch((error) => {
      error?.response?.data?.messages?.forEach((message) => {
        toast.error(message);
      });
      return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
    });

  return dispatch({ type: ON_PAGE_DROP, payload: dragInfo });
};

export const onAddUnassignedDocumentAction =
  ({ wiId, from }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_UNASSIGNED_DOCUMENT_LOADING,
      payload: { wiId, from },
    });
    const cachedReducer = getState().groupingReducer;
    api
      .jsonAPI()
      .post(`/work_items/${wiId}/add_unassigned_pages`)
      .then((res) => {
        dispatch({
          type: ADD_UNASSIGNED_DOCUMENT_SUCCESS,
          payload: { from, newWI: res.data, wiId },
        });
        toast.success(
          'The document was successfully added to the top of the list',
        );
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({
          type: SET_GROUPING_DATA,
          payload: cachedReducer,
        });
      });
  };

export const onMovePageToNewDocumentAction =
  (dragInfo) => async (dispatch, getState) => {
    const { pageIds, documentId, wiId, from, to, oldWIId } = dragInfo;
    const cachedReducer = getState().groupingReducer;
    api
      .jsonAPI()
      .post(`/work_items/${wiId}/add_unassigned_pages`)
      .then((res) => {
        dispatch({
          type: ADD_UNASSIGNED_DOCUMENT_SUCCESS,
          payload: { from: to, newWI: res.data, wiId },
        });
        return res.data;
      })
      .then((newWI) => {
        const activeDocuments = newWI.documents
          .filter((document) => !document.discarded)
          .sort((a, b) => {
            if (
              a.layout_name === 'Unassigned' &&
              b.layout_name !== 'Unassigned'
            ) {
              return 1;
            }
            if (
              a.layout_name !== 'Unassigned' &&
              b.layout_name === 'Unassigned'
            ) {
              return -1;
            }
            return new Date(a?.created_at) - new Date(b?.created_at);
          });
        dispatch(
          onPageDropAction({
            newDocumentId: activeDocuments[activeDocuments.length - 1].id,
            oldDocumentId: documentId,
            pageIds,
            newWorkItemId: newWI.id,
            oldWorkItemId: oldWIId,
            to,
            from,
          }),
        );
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({
          type: SET_GROUPING_DATA,
          payload: cachedReducer,
        });
      });
  };

export const onRemoveWorkItemAction =
  ({ id, from }) =>
  async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;
    dispatch({ type: REMOVE_WORK_ITEM_LOADING });
    api
      .jsonAPI()
      .delete(`/work_items/${id}`)
      .then(() => {
        dispatch({ type: REMOVE_WORK_ITEM_SUCCESS, payload: { id, from } });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
      });
  };

export const onRemoveEmptyWorkItemAction =
  ({ id, from }) =>
  async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;
    dispatch({ type: REMOVE_WORK_ITEM_LOADING });
    api
      .jsonAPI()
      .delete(`/work_items/${id}/delete_blank`)
      .then(() => {
        dispatch({ type: REMOVE_WORK_ITEM_SUCCESS, payload: { id, from } });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
      });
  };

export const cloneWorkItemAction = (id) => async (dispatch) => {
  await toast
    .promise(api.jsonAPI().post(`/work_items/${id}/duplicate`), {
      loading: 'Cloning work item...',
      success: (res) => `New work item ${res?.data?.id} successfully created`,
      error: 'Error while cloning work item',
    })
    .then((res) => {
      dispatch({ type: CLONE_WORK_ITEM_SUCCESS, payload: res.data });
    })
    .catch((error) => {
      error?.response?.data?.messages?.forEach((message) => {
        toast.error(message);
      });
    });
};

export const addNewWorkItemAction =
  (parentId) => async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;
    dispatch({ type: ADD_NEW_WORK_ITEM_LOADING });
    return api
      .jsonAPI()
      .post(
        `/work_items?batch_id=${cachedReducer.leftSide?.data[0]?.batch?.data.id}`,
        {
          parent_id: parentId,
        },
      )
      .then((res) => {
        dispatch({ type: ADD_NEW_WORK_ITEM_SUCCESS, payload: res.data });
        return res.data;
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
        throw error;
      });
  };

export const getDocumentsTypes = () => async (dispatch) => {
  const url = 'document_types';
  api
    .jsonAPI()
    .get(url, { params: { 'page[number]': 0 } })
    .then((res) => {
      const options = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          alias: item?.alias || null,
          visible: item.visible,
        };
      });
      dispatch(setDocumentTypes(options));
    })
    .catch((err) => console.error(err));
};

export const changeDocumentType =
  ({ selectedType, documentId, wiId, from }) =>
  async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;

    const dataFormatter = new Jsona();
    const data = {
      type: 'workItem',
      id: wiId,
      documents: [
        {
          id: documentId,
          document_type: selectedType.label,
          layout_name: selectedType.label,
        },
      ],
    };
    const jsonAPIData = dataFormatter.serialize({ stuff: data });

    api
      .jsonAPI()
      .patch(`work_items/${wiId}/documents`, jsonAPIData)
      .then((res) => {
        toast.success('Updated!');
        dispatch({
          type: CHANGE_DOCUMENT_TYPE,
          payload: { from, wiId, newWI: res.data },
        });
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
      });
  };
export const resetGrouping = () => async (dispatch) => {
  dispatch({ type: RESET_GROUPING });
};

export const updateSavedTags = (tags) => (dispatch) => {
  return dispatch({ type: UPDATE_SAVED_TAGS, payload: tags });
};

export const setEditedOutputField = (field) => (dispatch) => {
  return dispatch({ type: UPDATE_EDITED_FIELD, payload: field });
};

export const removeDocument = (documentId, from, wiId) => async (dispatch) => {
  api
    .jsonAPI()
    .delete(`work_items/${wiId}/documents/${documentId}`)
    .then(({ data }) => {
      toast.success(t('FileRemoved'));
      dispatch({
        type: REMOVE_DOCUMENT,
        payload: { from, wiId, newWI: data },
      });
    })
    .catch((e) => {
      toast.error(t('FileNotRemoved'));
      // eslint-disable-next-line no-console
      console.log(e);
    });
};

export const getAdditionalFeesOptions = () => async (dispatch, getState) => {
  const cachedOptions = getState()?.workItemReducer?.additionalFeesOptions;
  if (!cachedOptions?.length) {
    await api
      .restAPI()
      .get('accessorial_fees')
      .then((res) => {
        const options = res?.data?.map((item) => ({
          label: item,
          value: item,
        }));
        return dispatch({
          type: SET_ADDITIONAL_FEES_OPTIONS,
          payload: options,
        });
      })
      .catch((err) => console.error(err));
  }
};

export const getApprovalOverrideOptions = () => async (dispatch, getState) => {
  const cachedOptions = getState()?.workItemReducer?.approvalOverrideOptions;
  if (!cachedOptions?.length) {
    await api
      .restAPI()
      .get('approval_overrides')
      .then((res) => {
        const options = res?.data?.map((item) => ({
          label: item,
          value: item,
        }));
        return dispatch({
          type: SET_APPROVAL_OVERRIDE_OPTIONS,
          payload: options,
        });
      })
      .catch((err) => console.error(err));
  }
};

export const getGroupingLeftSide = (id) => async (dispatch, getState) => {
  const cachedReducer = getState().groupingReducer;
  dispatch({ type: GET_GROUPING_LEFT_SIDE_LOADING, payload: {} });
  api
    .jsonAPI()
    .get(`work_items/${id}`)
    .then((res) => {
      dispatch({
        type: GET_GROUPING_LEFT_SIDE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      error?.response?.data?.messages?.forEach((message) => {
        toast.error(message);
      });
      return dispatch({
        type: GET_GROUPING_LEFT_SIDE_ERROR,
        payload: cachedReducer,
      });
    });
};
