import { Dropdown } from 'antd';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import React, { useState } from 'react';

import { setWorkItem as setWorkItemAction } from '@actions';

import { createMenuOptions } from './DropdownSettings';
import s from './styles.module.scss';
import { updateWorkItemAction } from './api/api';

const DropdownMenu = ({ disabled, onFetchWorkItem }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const workItem = useSelector((state) => state.workItemReducer.workItem);
  const allowedActions = workItem?.allowedActions;

  const menuOptions = createMenuOptions(allowedActions);

  const [isLoading, setIsLoading] = useState(false);

  const onAction = async (actionKey) => {
    setIsLoading(true);
    try {
      const data = await updateWorkItemAction(id, actionKey);

      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });

      const fullWorkItemData = await onFetchWorkItem();

      if (fullWorkItemData && workItem.id === data.id) {
        dispatch(setWorkItemAction(fullWorkItemData));
      }
    } catch (err) {
      err?.response?.data?.messages?.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onReject = () => {
    onAction('reject');
  };

  const onRestore = () => {
    onAction('restore');
  };

  const onPause = () => {
    onAction('pause');
  };

  const onResume = () => {
    onAction('resume');
  };

  const onSendForApproval = () => {
    onAction('send_for_approval');
  };

  const onApprove = () => {
    onAction('approve');
  };

  const onFactor = () => {
    onAction('factor');
  };

  const actions = {
    reject: onReject,
    send_for_approval: onSendForApproval,
    pause: onPause,
    resume: onResume,
    restore: onRestore,
    approve: onApprove,
    factor: onFactor,
  };

  const handleActions = ({ key }) => {
    const action = actions[key];
    action?.();
  };

  return (
    <Dropdown
      menu={{ items: menuOptions, onClick: handleActions }}
      trigger={['click']}
      disabled={disabled || !allowedActions?.length || isLoading}
    >
      <div
        className={clsx(
          'ui',
          'primary',
          'button',
          s.button,
          (disabled || !allowedActions?.length || isLoading) && s.disabled,
        )}
      >
        {isLoading ? 'Processing...' : 'Action'}
        <Icon name="angle down" className={s.icon} />
      </div>
    </Dropdown>
  );
};

export default DropdownMenu;
