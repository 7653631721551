import { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { CloseOutlined } from '@ant-design/icons';
import { Spin, Alert } from 'antd';
import { Lightbox } from 'react-modal-image';
import withScrolling from 'react-dnd-scrolling';
import { debounce } from 'lodash';

import api from '@services/axios';
import s from '@components/GroupingNew/GroupingNew.module.scss';
import {
  addNewWorkItemAction,
  changeDocumentType,
  cloneWorkItemAction,
  getDocumentsTypes,
  getGroupingLeftSide,
  getWorkItemByFilter,
  getWorkItemDocumentsById,
  onAddUnassignedDocumentAction,
  onDocumentDropAction,
  onMovePageToNewDocumentAction,
  onPageDropAction,
  onRemoveWorkItemAction,
  onRemoveEmptyWorkItemAction,
  removeDocument,
  resetGrouping,
  resetWorkItem,
} from '@actions';
import WorkItemCard from '@components/WorkItemCard/WorkItemCard';
import useFilters from '@hooks/useFilters';
import GroupingFilters from '@components/GroupingNew/components/GroupingFilters/GroupingFilters';
import { generatePdfFromDoc } from '@components/GroupingNew/helpers';
import NewModal from '@components/Modal/NewModal';
import { WORK_ITEM_STATUSES } from '@constants';
import i18n from '@locales/i18n';

import { closeGrouping, fetchFiltersData } from './api/api';

const NavigationGuard = ({
  hasUnsavedChanges,
  onCleanup,
  setHasUnsavedChanges,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(null);
  const [pendingAction, setPendingAction] = useState(null);
  const initialLocation = useRef(location);

  const handleBeforeUnload = useCallback(
    (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = i18n.t('UnsavedChangesContent');
        return i18n.t('UnsavedChangesContent');
      }
      return undefined;
    },
    [hasUnsavedChanges],
  );

  useEffect(() => {
    const handleBeforeUnloadEvent = (e) => handleBeforeUnload(e);

    window.addEventListener('beforeunload', handleBeforeUnloadEvent, {
      capture: true,
    });

    const handleKeyDown = (e) => {
      if (hasUnsavedChanges) {
        if (
          (e.ctrlKey && e.key === 'r') ||
          e.key === 'F5' ||
          (e.ctrlKey && e.key === 'w') ||
          (e.ctrlKey && e.key === 'F4')
        ) {
          e.preventDefault();
          setPendingAction('reload');
          setShowModal('Confirm Page Reload');
          return false;
        }
      }
      return undefined;
    };

    const handlePopState = () => {
      if (hasUnsavedChanges) {
        window.history.pushState(null, '', initialLocation.current.pathname);
        setPendingAction({ type: 'navigate', to: location });
        setPendingAction('navigation');
        setShowModal('Confirm Navigation');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('popstate', handlePopState);

    window.history.pushState(null, '', location.pathname);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent, {
        capture: true,
      });
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleBeforeUnload, hasUnsavedChanges, location, initialLocation]);

  const handleModalConfirm = async () => {
    try {
      await onCleanup();
    } catch (error) {
      console.error('Cleanup failed:', error);
    } finally {
      setShowModal(null);
      setPendingAction(null);
    }
  };

  const handleModalCancel = () => {
    setShowModal(null);
    setPendingAction(null);
  };

  const getModalContent = () => {
    switch (pendingAction) {
      case 'reload':
        return {
          title: i18n.t('ConfirmReload'),
          message: i18n.t('ConfirmReloadContent'),
        };
      case null:
        return {
          title: i18n.t('UnsavedChanges'),
          message: i18n.t('UnsavedChangesContent'),
        };
      case 'navigation':
        return {
          title: i18n.t('ConfirmNavigation'),
          message: i18n.t('ConfirmNavigationContent'),
        };
      default:
        return {
          title: i18n.t('UnsavedChanges'),
          message: i18n.t('UnsavedChangesContent'),
        };
    }
  };

  if (hasUnsavedChanges) {
    const modalContent = getModalContent();

    return (
      <NewModal
        title={modalContent.title}
        submitButtonText="Continue"
        visible={showModal}
        onSubmit={handleModalConfirm}
        onCrossClick={handleModalCancel}
        cancelButtonVisible={false}
      >
        <p>{modalContent.message}</p>
      </NewModal>
    );
  }

  return null;
};
const ScrollingComponent = withScrolling('div');

const GroupingNew = ({ hasUnsavedChanges, setHasUnsavedChanges }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLeftSideLoading = useSelector(
    (state) => state?.groupingReducer?.leftSide?.isLoading,
  );
  const leftSideWI = useSelector(
    (state) => state?.groupingReducer?.leftSide?.data,
  );
  const currenWorkItems = useSelector(
    (state) => state?.groupingReducer?.rightSide?.data,
  );
  const isWorkItemsLoading = useSelector(
    (state) => state?.groupingReducer?.rightSide?.isLoading,
  );
  const readOnly =
    leftSideWI?.[0]?.status?.name === WORK_ITEM_STATUSES.rejected ||
    leftSideWI?.[0]?.status?.name === WORK_ITEM_STATUSES.approved ||
    leftSideWI?.[0]?.status?.name === WORK_ITEM_STATUSES.error ||
    leftSideWI?.[0]?.status?.name === WORK_ITEM_STATUSES.completed;

  const [filtersData, setFiltersData] = useState({
    batchOptions: [],
    emailOptions: [],
    loadNumbersOptions: [],
    subjectLineOptions: [],
    isLoading: true,
  });
  const [selectedPage, setSelectedPage] = useState({});
  const [filters, updateFilters] = useFilters({
    grouping: true,
  });
  const [selectedPages, setSelectedPages] = useState({
    documentId: '',
    pages: [],
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [involvedWiIds, setInvolvedWiIds] = useState([]);
  const [emptyWiIds, setEmptyWiIds] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleCleanup = async () => {
    try {
      setRemoveModalVisible(true);
      return true;
    } catch (error) {
      console.error('Cleanup failed:', error);
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  const addUniqueInvolvedWiId = (wiIds) => {
    setInvolvedWiIds((prevIds) => [...new Set([...prevIds, ...wiIds])]);
  };

  const fetchCleanupEmptyWorkItems = async (wiIds) => {
    const response = await api
      .restAPI()
      .get(`/work_items/cleanup_empty?id[]=${wiIds.join('&id[]=')}`);
    return response.data;
  };

  const onPageClick = (page) => {
    setSelectedPage(page);
  };

  const proceedWithClose = () => {
    dispatch(resetWorkItem());
    dispatch(resetGrouping());
    navigate(`/workitems/${leftSideWI?.[0].id}`);
  };

  const onClosePage = async () => {
    if (hasUnsavedChanges) {
      setRemoveModalVisible(true);
    } else {
      proceedWithClose();
    }
  };
  const onDocumentTypeChange = ({ selectedType, documentId, wiId, from }) => {
    dispatch(changeDocumentType({ selectedType, documentId, wiId, from }));
  };
  const onDocumentRemove = (removedDocId, from, wiId) => {
    dispatch(removeDocument(removedDocId, from, wiId));
    addUniqueInvolvedWiId([wiId]);
  };

  const onSelectPage = ({ pageId, documentId }) => {
    if (selectedPages.documentId !== documentId) {
      setSelectedPages({
        documentId,
        pages: [pageId],
      });
    } else {
      const newPages = [...selectedPages.pages];
      if (!newPages.includes(pageId)) {
        setSelectedPages({
          documentId,
          pages: [...selectedPages.pages, pageId],
        });
      } else {
        newPages.splice(newPages.indexOf(pageId), 1);
        setSelectedPages({
          documentId,
          pages: newPages,
        });
      }
    }
  };

  const onWorkItemOpen = (wi) => {
    if (wi.documents === undefined) {
      dispatch(getWorkItemDocumentsById(wi.id));
    }
  };
  const onDocumentDrop = (dragInfo) => {
    if (dragInfo.newWorkItemId !== dragInfo.oldWorkItemId) {
      dispatch(onDocumentDropAction(dragInfo));
      addUniqueInvolvedWiId([dragInfo.oldWorkItemId]);
    }
  };

  const onAddUnassignedDocument = ({ wiId, from }) => {
    dispatch(onAddUnassignedDocumentAction({ wiId, from }));
  };

  const onMovePageToNewDocument = (dragInfo) => {
    unSelectSelectedPages();
    dispatch(onMovePageToNewDocumentAction(dragInfo));
    addUniqueInvolvedWiId([dragInfo.oldWIId]);
  };

  const onPageDrop = (dragInfo) => {
    unSelectSelectedPages();
    dispatch(onPageDropAction(dragInfo));
    if (dragInfo.newWorkItemId !== dragInfo.oldWorkItemId) {
      addUniqueInvolvedWiId([dragInfo.oldWorkItemId]);
    }
  };

  const unSelectSelectedPages = () => {
    setSelectedPages({
      documentId: '',
      pages: [],
    });
  };

  const onWorkItemRemove = (wi) => {
    if (wi.id && wi.from) {
      if (emptyWiIds.includes(Number(wi.id))) {
        dispatch(onRemoveEmptyWorkItemAction({ id: wi.id, from: 'rightSide' }));
      } else {
        dispatch(onRemoveWorkItemAction(wi));
      }
    }
  };

  const onRemoveClick = () => {
    emptyWiIds.forEach((wiId) => {
      dispatch(onRemoveEmptyWorkItemAction({ id: wiId, from: 'rightSide' }));
    });
    setRemoveModalVisible(false);
    proceedWithClose();
  };

  useEffect(() => {
    const handleReset = () => {
      dispatch(resetWorkItem());
      dispatch(resetGrouping());
    };
    window.addEventListener('resetAppState', handleReset);
    return () => window.removeEventListener('resetAppState', handleReset);
  }, []);

  useEffect(() => {
    const handleReset = () => {
      dispatch(resetWorkItem());
      dispatch(resetGrouping());
    };
    window.addEventListener('resetAppState', handleReset);
    return () => window.removeEventListener('resetAppState', handleReset);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetWorkItem());
      dispatch(resetGrouping());
      closeGrouping(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const fetchData = debounce(async () => {
      const tempWiIds = currenWorkItems.map((item) => item.id);
      console.log('currenWorkItems IDs:', tempWiIds);

      if (tempWiIds.length > 0) {
        const data = await fetchCleanupEmptyWorkItems(tempWiIds);
        if (data.length > 0) {
          setHasUnsavedChanges(i18n.t('NavigationBlockCOntent'));
          setEmptyWiIds(data);
        } else {
          setHasUnsavedChanges(null);
        }
      }
    }, 500);

    fetchData();

    return () => {
      fetchData.cancel();
    };
  }, [currenWorkItems]);

  useEffect(() => {
    dispatch(getGroupingLeftSide(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(getDocumentsTypes());
    setFiltersData({
      isLoading: true,
    });
    fetchFiltersData()
      .then((data) => {
        const {
          batchOptions,
          emailOptions,
          loadNumbersOptions,
          subjectLineOptions,
        } = data;
        setFiltersData({
          batchOptions,
          emailOptions,
          loadNumbersOptions,
          subjectLineOptions,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error('Error fetching filter options:', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      dispatch(getWorkItemByFilter(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const trigger = leftSideWI?.[0]?.id;

  useEffect(() => {
    if (leftSideWI?.[0]?.id) {
      setFirstLoad(false);
      updateFilters({
        'filter[batchId]': [Number(leftSideWI?.[0].batch?.data?.id)],
        'filter[groupingClientId]': [
          Number(leftSideWI?.[0]?.outputFields?.client.id),
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const onAddNewWorkItem = () => {
    dispatch(addNewWorkItemAction(id))
      .then((newWorkItem) => {
        addUniqueInvolvedWiId([newWorkItem.id]);
      })
      .catch((error) => {
        console.error('Error adding new work item:', error);
      });
  };
  const onCloneWorkItem = (workItem) => {
    if (workItem?.id) {
      dispatch(cloneWorkItemAction(workItem.id));
    }
  };

  if (isLeftSideLoading) {
    return (
      <div className={s.loading}>
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div className={s.wrapper}>
      <NavigationGuard
        onCleanup={handleCleanup}
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />
      <NewModal
        title={i18n.t('DeleteModalTitle')}
        submitButtonText="Delete"
        onCancel={() => proceedWithClose()}
        visible={removeModalVisible}
        onCrossClick={() => setRemoveModalVisible(false)}
        onSubmit={onRemoveClick}
      >
        {i18n.t('DeleteModalTitleContent')} <b>{emptyWiIds.join(', ')}</b>
      </NewModal>
      {selectedPage.src && (
        <Lightbox
          className={s.lightbox}
          medium={selectedPage.image_url}
          onClose={() => setSelectedPage({})}
        />
      )}
      <div className={s.header}>
        <div className={s.headerLeft}>
          <div className={s.title}>
            grouping (batch#{leftSideWI?.[0]?.batch?.data?.id})
          </div>
          <div className={s.companyName}>
            {leftSideWI?.[0]?.outputFields?.client?.name}
          </div>
          <div className={s.wiNumber}>WI#{leftSideWI?.[0]?.id}</div>
        </div>
        <CloseOutlined className={s.headerClose} onClick={onClosePage} />
      </div>
      <div className={s.content}>
        <ScrollingComponent className={s.contentLeft}>
          <div className={s.workItems}>
            {leftSideWI?.length > 0 &&
              leftSideWI?.map((mappedWorkItem) => (
                <WorkItemCard
                  from="leftSide"
                  defaultOpen
                  onWorkItemOpen={() => {}}
                  key={mappedWorkItem.id}
                  workItem={mappedWorkItem}
                  onDocumentDrop={onDocumentDrop}
                  onPageDrop={onPageDrop}
                  onWorkItemRemove={onWorkItemRemove}
                  onAddDocument={onAddUnassignedDocument}
                  onMovePageToNewDocument={onMovePageToNewDocument}
                  workItems={currenWorkItems}
                  onDownloadDocument={generatePdfFromDoc}
                  onPageClick={onPageClick}
                  onDocumentTypeChange={onDocumentTypeChange}
                  readOnly={readOnly}
                  onDocumentRemove={onDocumentRemove}
                  onSelectPage={onSelectPage}
                  selectedPages={selectedPages.pages}
                  onClone={onCloneWorkItem}
                  hasError={emptyWiIds.includes(Number(mappedWorkItem.id))}
                  hasUnsavedChanges={hasUnsavedChanges}
                />
              ))}
          </div>
        </ScrollingComponent>
        <div className={s.contentRight}>
          <div className={s.filtersHeader}>
            <GroupingFilters
              filtersData={filters}
              batchOptions={filtersData.batchOptions}
              emailsOptions={filtersData.emailOptions}
              loadNumbersOptions={filtersData.loadNumbersOptions}
              subjectLineOptions={filtersData.subjectLineOptions}
              updateFilters={updateFilters}
              disableFilters={isWorkItemsLoading || filtersData.isLoading}
            />
            <Button
              type="button"
              className={s.addButton}
              onClick={onAddNewWorkItem}
            >
              Add Work Item
            </Button>
          </div>

          <ScrollingComponent className={s.workItems}>
            {!currenWorkItems?.filter((wi) => wi.id !== leftSideWI?.[0].id)
              ?.length &&
              !isWorkItemsLoading && (
                <div className={s.loadingWrapper}>
                  No Work Items found those match search criteria
                </div>
              )}
            {isWorkItemsLoading && (
              <div className={s.loadingWrapper}>
                <Spin />
              </div>
            )}
            {currenWorkItems?.length > 0 &&
              [...currenWorkItems]
                .filter(
                  (currentWorkItem) =>
                    parseInt(currentWorkItem.id, 10) !==
                      parseInt(leftSideWI[0].id, 10) &&
                    !currentWorkItem?.discardedAt?.length,
                )
                .map((currentWorkItem) => (
                  <WorkItemCard
                    from="rightSide"
                    key={currentWorkItem.id}
                    workItem={currentWorkItem}
                    onDocumentDrop={onDocumentDrop}
                    onPageDrop={onPageDrop}
                    onWorkItemRemove={onWorkItemRemove}
                    onAddDocument={onAddUnassignedDocument}
                    onMovePageToNewDocument={onMovePageToNewDocument}
                    onWorkItemOpen={onWorkItemOpen}
                    workItems={currenWorkItems}
                    onDownloadDocument={generatePdfFromDoc}
                    onPageClick={onPageClick}
                    onDocumentTypeChange={onDocumentTypeChange}
                    onDocumentRemove={onDocumentRemove}
                    removable
                    onSelectPage={onSelectPage}
                    selectedPages={selectedPages.pages}
                    onClone={onCloneWorkItem}
                    hasError={emptyWiIds.includes(Number(currentWorkItem.id))}
                    hasUnsavedChanges={hasUnsavedChanges}
                  />
                ))}
          </ScrollingComponent>
        </div>
      </div>
    </div>
  );
};

export default GroupingNew;
