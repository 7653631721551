import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { WORK_ITEM_STATUSES, WORK_ITEM_DISPLAY_STATUSES } from '@constants';
import {
  setWorkItem as setWorkItemAction,
  resetWorkItem,
  setAlerts as setAlertsAction,
  setWorkItem,
  updateDocuments,
  updateWorkItemStatus,
} from '@actions';
import ApplicationLoader from '@components/aria/UI/ApplicationLoader';
import EmailClient from '@components/EmailClient/EmailClient';
import actionCableConstants from '@components/aria/Misc/constants';
import ActionCableContainer from '@components/aria/Misc/ActionCableContainer';

import HSWidget from '../aria/Application/Activity/Hyperscience/HSWidget';

import * as apiService from './api/api';
import s from './styles.module.scss';
import PDFViewerWrapper from './PDFViewerWrapper';
import Header from './Header';

const WorkItemDetailed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const workItem = useSelector((state) => state.workItemReducer.workItem);
  const currentUser = useSelector((state) => state.currentUserReducer.user);
  const storedFilters = useSelector((state) => state.filtersReducer.filters);
  const { t } = useTranslation();
  const { id } = useParams();

  const [isLoading, setIsloading] = useState(true);
  const [widget, setWidget] = useState(false);
  const [showEmails, setShowEmails] = useState(false);
  const [savedEmails, setSavedEmails] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  const isWorkItem = workItem?.id && Object?.keys(workItem)?.length;

  const onActionCableRefresh = (refresh) => {
    const { type } = refresh;
    const { actionCableActions } = actionCableConstants;
    switch (type) {
      case actionCableActions.lock: {
        const { id: lockedID, lock } = refresh;
        if (
          Number(id) === Number(lockedID) &&
          currentUser.id !== lock.user.id
        ) {
          toast.error(`This work item was reassign by the ${lock.user.email}`);
          dispatch(setWorkItem({ ...workItem, locked: lock }));
        }
        break;
      }
      case actionCableActions.unlock: {
        const { id: lockedID } = refresh;
        if (Number(id) === Number(lockedID)) {
          onUnlockWI();
        }
        break;
      }
      case actionCableActions.documents_updated: {
        const { id: updatedWID } = refresh;
        if (Number(id) === Number(updatedWID)) {
          fetchUpdatedDocuments(updatedWID);
        }
        break;
      }
      case actionCableActions.status_change: {
        const { id: updatedWID, status } = refresh;
        if (Number(id) === Number(updatedWID)) {
          dispatch(
            updateWorkItemStatus({
              caption: WORK_ITEM_DISPLAY_STATUSES[status],
              name: status,
            }),
          );
        }
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(`Unknown action cable action: ${type}`);
      }
    }
  };

  const fetchUpdatedDocuments = async (updatedWID) => {
    try {
      const documents = await apiService.fetchUpdatedDocuments(updatedWID);
      setIsloading(false);
      dispatch(updateDocuments(documents));
    } catch (err) {
      navigate('/');
      console.error('Error fetching updated documents:', err);
    }
  };

  useEffect(() => {
    return () => dispatch(resetWorkItem());
  }, [dispatch]);

  const onEmailSave = async (email) => {
    try {
      const data = await apiService.saveEmail(
        email,
        workItem.outputFields.client.id,
      );
      setSavedEmails([...savedEmails, data]);
    } catch (err) {
      console.error('Error saving email:', err);
    }
  };

  const onUnlockWI = async () => {
    try {
      const data = await apiService.unlockWorkItem(id);
      dispatch(setWorkItem({ ...workItem, locked: data }));
      toast.success('Work item was successfully reassigned!');
    } catch (err) {
      console.error('Error unlocking work item:', err);
    }
  };

  const onRemoveWI = () => {
    const { id: workItemId, discardedAt } = workItem;
    const message = discardedAt
      ? {
          loading: 'Restoring work item...',
          success: 'Work item was successfully restored!',
          error: 'Work item was not restored!',
        }
      : {
          loading: 'Removing work item...',
          success: 'Work item was successfully removed!',
          error: 'Work item was not removed!',
        };

    toast.promise(
      apiService
        .removeOrRestoreWorkItem(workItemId, discardedAt)
        .then((res) => {
          if (discardedAt) {
            dispatch(setWorkItem(res));
          } else {
            navigate('/');
          }
        })
        .catch((err) => {
          console.error('Error:', err);
        }),
      message,
    );
  };

  const onTagsChange = async (tags) => {
    try {
      const updatedTags = await apiService.updateTags(workItem.id, tags);
      dispatch(setWorkItem({ ...workItem, tags: updatedTags }));
    } catch (err) {
      console.error('Error updating tags:', err);
    }
  };

  const onWorkItemChange = async (type) => {
    if (type === 'prev' || type === 'next') {
      try {
        const data = await apiService.changeWorkItem(
          workItem.id,
          type,
          storedFilters,
        );
        if (data.id) {
          setCurrentItemIndex(data.index);
          navigate(`/workitems/${data.id}`);
        } else {
          toast.error(`Can't find ${type} work item`);
        }
      } catch (err) {
        console.error(`Error fetching ${type} work item:`, err);
        toast.error(`Can't find ${type} work item`);
      }
    }
  };

  const fetchTags = async () => {
    try {
      const tags = await apiService.fetchTags();
      setAvailableTags(tags);
    } catch (err) {
      console.error('Error fetching tags:', err);
    }
  };

  const fetchWorkItem = async (workItemId) => {
    try {
      const data = await apiService.fetchWorkItem(workItemId);
      setIsloading(false);
      dispatch(setWorkItemAction(data));
    } catch (err) {
      navigate('/');
      console.error('Error fetching work item:', err);
    }
  };

  const fetchEmails = async (clientId) => {
    try {
      const emails = await apiService.fetchEmails(clientId);
      setSavedEmails(emails);
    } catch (err) {
      console.error('Error fetching emails:', err);
    }
  };

  const fetchAlerts = async () => {
    try {
      const alerts = await apiService.fetchAlerts();
      dispatch(setAlertsAction(alerts));
    } catch (err) {
      console.error('Error fetching alerts:', err);
    }
  };

  useEffect(() => {
    setIsloading(true);
    fetchTags();
    fetchWorkItem(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (workItem?.outputFields?.client?.id) {
      fetchEmails(workItem.outputFields.client.id);
    } else {
      setSavedEmails([]);
    }
  }, [workItem?.outputFields?.client?.id]);

  useEffect(() => {
    fetchAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!isWorkItem || isLoading) {
    return <ApplicationLoader text={t('Loading work item...')} />;
  }

  const clientItem = workItem?.outputFields;
  const clientName = clientItem?.client?.name;
  const clientEmail = workItem?.batch?.data?.attributes?.email;
  const isViewer =
    currentUser?.roles?.includes('viewer') &&
    !currentUser?.roles?.includes('super_admin');
  const editableForCurrentUser = workItem?.locked?.user?.id === currentUser?.id;
  const discarded = workItem?.discardedAt;
  const readOnly =
    isViewer ||
    workItem?.status?.name === WORK_ITEM_STATUSES.rejected ||
    workItem?.status?.name === WORK_ITEM_STATUSES.approved ||
    workItem?.status?.name === WORK_ITEM_STATUSES.error ||
    workItem?.status?.name === WORK_ITEM_STATUSES.completed ||
    workItem?.status?.name === WORK_ITEM_STATUSES.inProcess ||
    !editableForCurrentUser ||
    discarded?.length > 0;

  return (
    <>
      <ActionCableContainer
        active
        token={currentUser.jwt}
        channel="WorkItemsChannel"
        onReceived={onActionCableRefresh}
      />
      {showEmails && (
        <EmailClient readOnly={readOnly} onClose={() => setShowEmails(false)} />
      )}
      <div className={s.contentWrapper}>
        <Header
          hasCloseIcon
          onWorkItemChange={onWorkItemChange}
          workItem={workItem}
          clientName={clientName}
          clientEmail={clientEmail}
          widget={widget}
          setWidget={setWidget}
          onEmailsClick={() => setShowEmails(true)}
          isSaveActive={
            Boolean(workItem.outputFields?.client?.id) &&
            !savedEmails.find(
              (item) =>
                item?.email?.toLowerCase() === clientEmail?.toLowerCase(),
            ) &&
            !isLoading &&
            clientEmail?.length > 0 &&
            workItem.outputFields?.client?.email.toLowerCase() !==
              clientEmail.toLowerCase()
          }
          onSaveEmail={onEmailSave}
          lockedBy={editableForCurrentUser ? null : workItem?.locked}
          readOnly={readOnly}
          onUnlock={onUnlockWI}
          availableTags={availableTags}
          onTagsChange={onTagsChange}
          onRemove={onRemoveWI}
          discarded={discarded}
          actionsDisabled={isViewer}
          onFetchWorkItem={() => fetchWorkItem(id)}
          currentItemIndex={currentItemIndex}
        />
        {widget ? (
          <HSWidget
            service_url="https://transflohs.invk.cloud/"
            submission_id={1}
            closePoup={setWidget}
          />
        ) : (
          <>
            <PDFViewerWrapper workItem={workItem} readOnly={readOnly} />
          </>
        )}
      </div>
    </>
  );
};

export default WorkItemDetailed;
